import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import User from '@/model/User';
import Page from '@/model/Page';
import UserSettings from '@/model/settings/UserSettings';
import { userStore } from '@/store/UserStore';

export default class GeneratedUserServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getUsers(): Promise<User[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUsers', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const users: User[] = data.map(user => Object.assign(new User(), user))
                userStore.replaceUsers(users)
                return users
            } else return Promise.reject()
        })
    }


    _changeUserRoles(userName: string, roles: string[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeUserRoles', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.removeUser(userName)
            userStore.addOrReplaceUser(model)
            return model.userName
        })
    }

    _saveSettings(arg0: string | null, arg1: UserSettings | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('saveSettings', rpcParams, false) as Promise<void>
    }

    _getSettings(): Promise<UserSettings> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSettings', rpcParams, false).then((data: any) => {
            return Object.assign(new UserSettings(), data)
        })
    }

    _updateStatus(status: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateStatus', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.addOrReplaceUser(model)
            return model.userName
        })
    }

    getUsers(refresh?: boolean | number, sortBy?: string[] | string): SWR<User[], string[]> {
        //@ts-ignore
        const result: SWR<User[], string[]> = reactive(new SWR<User[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getUsers' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getUsers[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const users: User[] = []
                for (const id of data) {
                    const user: User | undefined = userStore.state.users.get(id)
                    if (user) {
                        users.push(user)
                    }
                }
                result.data = users
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getUsers().then((data: User[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(user => user.userName || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let users: User[] = [...userStore.state.users.values()]
        
        SortAndFilterUtil.sort(users, sortBy)
        
        result.data = users
        return result
    }

    updateStatus(status: string, refresh?: boolean | number): SWR<User | null, string> {
        //@ts-ignore
        const result: SWR<User | null, string> = reactive(new SWR<User | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_updateStatus' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_updateStatus[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((userName: string) => {
                result.data = userStore.state.users.get(userName) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._updateStatus(status).then((userName: string) => {
                call.data = userName
                result.data = userStore.state.users.get(userName) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = userStore.state.users.get(cached.data) || null
        }
        return result
    }


    getUser(userName: string): User | undefined {
        return userStore.state.users.get(userName)
    }
}
