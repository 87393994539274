<template>
  <div
    :class="elementClass"
    class="p-4 d-flex mx-2 my-1 card flex-row separator-bottom"
    style="cursor: pointer"
    @contextmenu="showContextMenu"
  >
    <div v-if="isSent" class="d-none d-md-block">
      <Avatar :username="email.toUserName" :label="(email.to || email.toEmail || []).map(e => e.split('<')[0]).join(', ')" generate-initials />
    </div>
    <div v-else class="d-none d-md-block">
      <Avatar :username="email.userName" :label="email.fromName && email.fromName.length > 0 ? email.fromName[0] : null" generate-initials />
    </div>
    <div class="flex-grow-1" style="width: 0; overflow: hidden" :class="{'w-100': isOnMobile, 'pl-4': !isOnMobile}">
      <div class="d-flex flex-row" style="min-width: 0">
        <p v-if="isSent" class="mb-1 text-dark font-weight-bold flex-grow-1 flex-shrink-1" style="text-overflow: fade">
          <!--<span class="badge badge-light"><translate>From</translate>:</span>&emsp;-->{{ (email.to || email.toEmail || []).join(', ') }}
        </p>
        <p v-else class="mb-1 text-dark font-weight-bold flex-grow-1 flex-shrink-1" style="text-overflow: fade">
          <!--<span class="badge badge-light"><translate>From</translate>:</span>&emsp;-->{{ (email.fromName || []).join(', ') }}
        </p>
        <div class="flex-shrink-0 d-flex">
          <!--<i v-if="!seen" class="fa fa-dot-circle mr-3 text-primary"></i>-->
          <i v-if="answered" class="pi pi-reply text-primary mr-2" style="margin-top: 0.1rem" />
          <i v-if="flagged" class="cil-flag text-danger mr-2 mt-1" />
          <i v-if="email.hasAttachment" class="fa fa-paperclip mr-1 mt-1 text-secondary" />
          <Button icon="cil-menu" class="p-button-text p-button-sm context-menu-btn" @click.stop.prevent="showMenu($event)" />
        </div>
      </div>
      <!--<p v-if="separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>To</translate>:</span>&emsp;{{ (email.to || []).join(', ') }}</p>
      <p v-if="separateRecipients && email.cc && email.cc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Cc</translate>:</span>&emsp;{{ (email.cc || []).join(', ') }}</p>
      <p v-if="separateRecipients && email.bcc && email.bcc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Bcc</translate>:</span>&emsp;{{ (email.bcc || []).join(', ') }}</p>
      <p v-if="!separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>Recipients</translate>:</span>&emsp;{{ allRecipients.join(', ') }}</p>-->
      <p v-if="email.subject" class="mb-1 text-dark">
        <!--<span class="badge badge-light" v-if="email.subject"><translate>Subject</translate>:</span>&emsp;-->{{ email.subject }}
      </p>
      <small v-if="email.textBody && email.textBody !== '.'" style="display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-bottom: 4px"><!--<span class="badge badge-light" v-if="email.subject"><translate>Subject</translate>:</span>&emsp;-->{{ email.textBody.substring(0,200) }}</small>
      <Skeleton v-else-if="email.isPreview && email.textBody !== '.'" height="18px" style="margin-bottom: 8px" />
    </div>
    <div v-if="email.receivedDate" :class="{ 'pl-2': !isOnMobile }">
      <p class="small" :class="{ 'mb-2': !isOnMobile, 'mb-0': isOnMobile }">
        {{ receivedDate }}
      </p>
    </div>
    <TieredMenu ref="menu" :model="menuItems" :popup="true" />
    <ContextMenu ref="contextMenu" :key="JSON.stringify(menuItems)" :model="menuItems" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/entry/Email"
import ContextMenu from "primevue/contextmenu"
import { ref } from "@vue/reactivity"
import MailFolder from "@/model/directory/MailFolder"
import Avatar from "@/components/common/Avatar.vue"
import Skeleton from 'primevue/skeleton'
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import Button from "primevue/button"
import TieredMenu from "primevue/tieredmenu"
import EmailUtil from "@/util/EmailUtil"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import dayjs from "@/util/dayjs"
import {emailStore} from "@/store/EmailStore"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  //@ts-ignore
  props: {
    folderId: String,
    item: [Email, Object],
    selected: {type: Boolean, default: false},
    separateRecipients: {type: Boolean, default: false},
    isSent: {type: Boolean, default: false}
  },
  components: {
    ContextMenu, Avatar, Skeleton, TieredMenu, Button
  },
  emits: [
    'email:task',
    'email:reply',
    'email:forward',
    'email:flag',
    'email:mark-unread',
    'email:move',
    'email:delete'
  ]
})
export default class EmailListItem extends Vue {

  i18n: Language = useGettext()
  folderId!: string

  item!: Email
  selected!: boolean
  isSent!: boolean

  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)

  menuItems: any[] = []

  menuItemFromFolder(folder: MailFolder): any {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
    const item: any = {
      label: mapping?.name || folder.name,
      icon: mapping?.icon || 'cil-inbox',
      command: () => { this.$emit('email:move', { id: this.email.originalId, sourceFolderId: this.folderId, targetFolderId: folder.originalId }) }
    }

    if (folder.subFolders && folder.subFolders.length > 0) {
      item['items'] = folder.subFolders.map(subFolder => this.menuItemFromFolder(subFolder))
    }

    return item
  }

  get date(): Date | null {
    return (this.email && this.email.receivedDate) ? new Date(this.email.receivedDate) : null
  }

  get isOnMobile(): boolean {
    return breakpointUtil.isOnMobile()
  }

  get elementClass(): string[] {
    let result = []
    let backgroundClass: string
    if (this.selected) {
      backgroundClass = 'bg-light'
    } else {
      backgroundClass = 'bg-hover-light'
    }
    if (!this.seen) {
      backgroundClass += ' unread'
    }
    result.push(backgroundClass)
    if(breakpointUtil.isOnMobile()){
      result.push("flex-wrap")
    }
    return result
  }

  get seen(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('SEEN') >= 0
    } else {
      return false
    }
  }

  get flagged(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('FLAGGED') >= 0
    } else {
      return false
    }
  }

  get answered(): boolean {
    if (this.email?.systemFlags) {
      return this.email.systemFlags.indexOf('ANSWERED') >= 0
    } else {
      return false
    }
  }

  get email(): Email {
    if (this.item.originalId && !this.item.textBody) {
      return emailStore.state.emails.get(this.item.originalId) || this.item
    } else {
      return this.item
    }
  }

  get receivedDate(): string {
    return this.email.receivedDate ? dayjs(this.email.receivedDate).format('LLL') : ''
  }

  showContextMenu(event: Event) {
    if (this.contextMenu) {
      this.updateMenuItems()
      this.menu.hide()
      void this.$nextTick(() => {
        this.contextMenu.toggle(event)
      })
    }
  }

  showMenu(event: Event) {
    if (this.menu) {
      this.updateMenuItems()
      this.contextMenu.hide()
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  updateMenuItems() {
    const folders: MailFolder[] | null = mailFolderServiceApi.getFolders().data

    const items: any[] = [
      {
        label: this.i18n.$gettext('Create or Edit Task'),
        icon: 'cil-task',
        command: () => {this.$emit('email:task', this.email)}
      },
      {
        label: this.i18n.$gettext('Reply'),
        icon: 'fa fa-reply',
        command: () => { this.$emit('email:reply', { id: this.email.originalId, folderId: this.folderId, replyAll: false }) }
      },
      {
        label: this.i18n.$gettext('Reply All'),
        icon: 'fa fa-reply-all',
        command: () => { this.$emit('email:reply', { id: this.email.originalId, folderId: this.folderId, replyAll: true }) }
      },
      {
        label: this.i18n.$gettext('Forward'),
        icon: 'fa fa-forward',
        command: () => { this.$emit('email:forward', { id: this.email.originalId, folderId: this.folderId }) }
      }
    ]

    if (this.flagged) {
      items.push({
        label: this.i18n.$gettext('Unflag'),
        icon: 'cil-flag',
        command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { FLAGGED: false } }) }
      })
    } else {
      items.push({
        label: this.i18n.$gettext('Flag'),
        icon: 'cil-flag',
        command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { FLAGGED: true } }) }
      })
    }

    if (this.seen) {
      items.push({
        label: this.i18n.$gettext('Mark unread'),
        icon: 'cil-eye-slash',
        command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { SEEN: false } }) }
      })
    } else {
      items.push({
        label: this.i18n.$gettext('Mark read'),
        icon: 'cil-eye',
        command: () => { this.$emit('email:flag', { id: this.email.originalId, folderId: this.folderId, systemFlags: { SEEN: true } }) }
      })
    }

    if (folders?.find(f => f.originalId === this.email.originalParentId)?.type === '\\Trash') {
      items.push( {
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => { this.$emit('email:delete', { id: this.email.originalId, folderId: this.folderId }) }
      })
    } else {
      items.push( {
        label: this.i18n.$gettext('Move to trash'),
        icon: 'cil-trash',
        command: () => { this.$emit('email:delete', { id: this.email.originalId, folderId: this.folderId }) }
      })
    }

    if (folders && folders.length > 0) {
      items.push({
        label: this.i18n.$gettext('Move to...'),
        icon: 'cil-folder-move',
        items: folders.map(folder => this.menuItemFromFolder(folder))
      })
    }

    this.menuItems = items
  }
}
</script>

<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";

.unread {
  box-shadow: inset .5rem 0 0 $uniki_primary;
}
</style>
