
import {Options, Vue} from "vue-class-component"
import Task from "../../model/entry/Task"
import AnimatedInput from "../common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Watch} from "vue-property-decorator"
import CalendarPicker from "primevue/calendar"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import Alarm from "@/model/common/caldav/Alarm"
import Conference from "@/model/common/caldav/Conference"
import {taskServiceApi} from "@/api/TaskServiceApi"
import InputSwitch from "primevue/inputswitch"
import {ref} from "@vue/reactivity"
import InfiniteList from "@/components/common/InfiniteList.vue"
import User from "@/model/User"
import TaskBoard from "@/model/directory/TaskBoard"
import Avatar from "@/components/common/Avatar.vue"
import {userServiceApi} from "@/api/UserServiceApi"
import UserPicker from "@/components/common/UserPicker.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import RecurrencePicker from "@/components/common/RecurrencePicker.vue"
import Organizer from "@/model/common/caldav/Organizer"
import {rpcClient} from "@/api/WebsocketClient"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import ColorPicker from "@/components/common/ColorPicker.vue"
import OverlayPanel from "primevue/overlaypanel"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import DatePicker from "@/components/common/DatePicker.vue"
import EmailUtil from "@/util/EmailUtil"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Textarea from "primevue/textarea"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Attachment from "@/model/common/caldav/Attachment"
import Chip from "primevue/chip"
import Slider from 'primevue/slider'
import breakpointUtil from "@/util/BreakpointUtil"
import TipTapTextArea from "@/components/common/TipTapTextArea.vue"
import Menu from "primevue/menu"
import featureSubset from "@/util/FeatureSubsets"
import Tags from "@/components/common/Tags.vue"
import AttachmentItem from "@/components/common/AttachmentItem.vue"
import Email from "@/model/entry/Email"
import EntryLink from "@/model/EntryLink"
import {entryLinkServiceApi} from "@/api/EntryLinkServiceApi"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import TaskBoardList from "@/model/common/TaskBoardList"
import EntryLinkMeta from "@/model/EntryLinkMeta"

@Options({
  components: {
    //@ts-ignore
    AnimatedInput, LoadingButton, InputText, Dialog, CalendarPicker, InputSwitch, InfiniteList, Avatar,
    UserPicker, AutoComplete, Dropdown, RecurrencePicker, ColorPicker, OverlayPanel, Button, TokenAttachmentList,
    DatePicker, Textarea, Chip, Slider, TipTapTextArea, Menu, Tags, AttachmentItem
  },
  //@ts-ignore
  props: {
    email: [Email, Object],
    showDialog: Boolean
  }
})
export default class TaskCreator extends Vue {

  featureSubset = featureSubset

  linkDescription!: string | null
  leftParentId!: string | null
  leftBackendId!: string | null
  //rightBackend!: string | null
  leftType!: string | null

  //leftBackend: string = "MAIL"
  rightType: string = "TASK"

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  //@ts-ignore
  colorPicker: OverlayPanel = ref<OverlayPanel>(null)
  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  description: HTMLElement = ref<HTMLElement>(null)

  saveLoading = false

  showDialog: boolean = false
  showDialogInternal: boolean = false

  email!: Email | null
  showRecurrencePicker = false

  newParticipant = ""
  selectableUsers: string[] = []

  start: Date | null = null
  due: Date | null = null
  summary: string | null = null
  completed: Date | null = null
  percentCompleted: number | null = null
  location: string | null = null
  taskBoardId: string | null = null
  taskBoardList: string | null = null
  classification = 'PRIVATE'
  priority = 'UNDEFINED'
  organizer: Organizer | null = null
  categories: string[] = []
  attendees: { attendee: Attendee, user: User | undefined }[] = []
  contacts: string[] = []
  alarms: Alarm[] = []
  color: string | null = null
  conferences: Conference[] = []
  recurring = false
  transparency = 'OPAQUE'
  recurrenceRule: RecurrenceRule = Object.assign(new RecurrenceRule(), {
    frequency: 'WEEKLY',
    interval: '1'
  })

  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  //@ts-ignore
  attachMenu: Menu = ref(null)
  attachments: {name: string, size: string, handle: string, loading: boolean, progress: number}[] = []
  taskAttachments: Attachment[] = []

  attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      const query: string = event.query.toLowerCase()
      return user.userName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.displayName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.email?.toLowerCase()?.indexOf(query) !== -1 ||
        user.uid?.toLowerCase()?.indexOf(query) !== -1
    }).map((user: User) => {
      let userTag: string = ""
      if (user.email) userTag = user.email
      if (user.displayName) userTag = user.displayName + " <" + userTag + ">"
      return userTag
    }).filter((str: string) => {return str !== "" })
  }

  getSaveToolTip(): string {
    if (!this.taskBoardId) return this.i18n.$gettext("No task board selected")
    else if (!this.summary) return this.i18n.$gettext("Summary must not be empty")
    else return this.i18n.$gettext("Save task")
  }

  save(): Promise<string | void> {
    if (this.validate) {
      let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
      if (hasIncompleteUploads){
        this.toast.error(this.i18n.$gettext("You cannot send the message while attachments are still uploading"))
        return Promise.reject()
      }

      if (this.start && this.due && this.due.getTime() < this.start.getTime()){
        this.toast.error(this.i18n.$gettext("Due date must be after start date"))
        return Promise.reject()
      }

      const task: Task = new Task()

      if (this.percentCompleted === 100 && task.percentCompleted !== 100) {
        this.completed = new Date()
      }

      task.start = this.start?.toISOString() || null
      task.due = this.due?.toISOString() || null
      task.completed = this.completed?.toISOString() || null
      task.percentCompleted = this.percentCompleted
      task.summary = this.summary
      task.location = this.location
      task.originalParentId = this.taskBoardId
      task.classification = this.classification
      task.description = this.editor?.getMarkdown()
      task.priority = this.priority
      task.organizer = this.organizer ? this.organizer : (rpcClient.session.user ? Object.assign(new Organizer(), {
        email: userServiceApi.userEmail,
        name: userServiceApi.userName, //TODO
      }) : null)
      task.categories = []
      this.categories?.forEach((cat: string) => {
        if (this.columnNames.indexOf(cat) < 0) {
          task.categories?.push(cat)
        }
      })
      if (this.taskBoardList && task.categories.indexOf(this.taskBoardList) < 0) {
        task.categories.push(this.taskBoardList)
      }
      task.attendees = (this.attendees || []).map(a => a.attendee)
      for (let attendee of task.attendees) {
        attendee.rsvp = true //TODO
      }
      task.contacts = this.contacts
      task.alarms = this.alarms
      task.color = this.color
      task.conferences = this.conferences
      task.recurrenceRule = this.recurring ? this.recurrenceRule : null
      task.attachments = this.taskAttachments
      task.fileTokens = this.attachmentcontrol.getFileTokens()

      this.saveLoading = true

      return taskServiceApi._addTask(task).then((id: string) => {
        this.toast.success(this.i18n.$gettext("Task created"))
        this.createEntryLink(id)
        return id
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Creating task failed"))
      }).finally(() => {
        this.saveLoading = false
        this.hide()
      })
    } else {
      return Promise.reject()
    }
  }

  get taskBoard(): TaskBoard | null {
    if (this.taskBoardId) {
      const boards: TaskBoard[] = taskBoardServiceApi.getTaskBoards(this.refresh).data || []
      return boards.find((board: TaskBoard) => {
        return board.originalId == this.taskBoardId
      }) || null
    } else {
      return null
    }
  }

  preselectColumn(event: any) {
    console.log(this.taskBoard)
    if (!this.taskBoard) {
      console.log("no task board")
      this.taskBoardList = null
      return
    }
    const defaultTaskList: TaskBoardList = taskBoardServiceApi.getDefaultTaskList(
      this.taskBoard, this.i18n.$gettext('Uncategorized')
    )
    console.log(defaultTaskList)
    this.taskBoardList = defaultTaskList.name
  }

  get columnNames(): string[] {
    let names: string[] = []
    if (this.taskBoard?.meta?.taskLists) {
      for (let list of this.taskBoard.meta?.taskLists) {
        if (list?.name) {
          names.push(list.name)
        }
      }
    }
    return names
  }

  get attachmentsWithFileName(): Attachment[] {
    return this.taskAttachments?.filter(a => a.file && a.file.fileName) || []
  }

  createEntryLink(rightBackendId: string): void {
    const entryLink: EntryLink = new EntryLink()
    entryLink.rightBackendId = rightBackendId
    //Will be set on server according to type
    //entryLink.leftBackend = this.leftBackend
    entryLink.rightType = this.rightType

    entryLink.leftBackendId = this.leftBackendId
    //Will be set on server according to type
    //entryLink.rightBackend = this.rightBackend
    entryLink.leftType = this.leftType

    entryLink.meta = new EntryLinkMeta()
    entryLink.meta.leftOriginalParentId = this.leftParentId,
    entryLink.meta.rightOriginalParentId = this.taskBoardId,
    entryLink.meta.description = this.linkDescription || this.summary


    entryLinkServiceApi._createLink(entryLink).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Creating entry link failed"))
    })
  }

  get validate(): boolean {
    return Boolean(this.taskBoardId) && Boolean(this.summary)
  }

  get taskboardOptions(): {label: string, value: string}[] {
    const options: {label: string, value: string}[] = []
    taskBoardServiceApi.taskBoards.forEach((tb: TaskBoard) => {
      if (tb.name && tb.originalId) {
        options.push({label: tb.name, value: tb.originalId})
      }
    })
    return options
  }

  classificationOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('Public'), value: 'PUBLIC'},
    {label: this.i18n.$gettext('Private'), value: 'PRIVATE'},
    {label: this.i18n.$gettext('Confidential'), value: 'CONFIDENTIAL'}
  ]

  priorityOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('High'), value: 'HIGH'},
    {label: this.i18n.$gettext('Medium'), value: 'MEDIUM'},
    {label: this.i18n.$gettext('Low'), value: 'LOW'},
    {label: this.i18n.$gettext('Not set'), value: 'UNDEFINED'}
  ]

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Member')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Assignee')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Stakeholder')
    }
  ]

  filterCategoriesOptions(currentInput: string): string[] {
    const options: string[] = taskServiceApi.getCategoriesOptions(this.taskBoardId || "")
    if (currentInput) {
      const lowerQuery = currentInput.toLowerCase()
      return options.filter(option => option.toLowerCase().includes(lowerQuery)).sort((o1: string, o2: string) => {
        let index1: number = o1.toLowerCase().indexOf(lowerQuery)
        let index2: number = o2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
    } else {
      return options
    }
  }

  hide() {
    this.showDialogInternal = false
    this.$emit('hide')
  }

  confirmClose() {
    this.confirm.require({
      message: this.i18n.$gettext('Do you want to save the task?'),
      header: this.i18n.$gettext('Save & Close'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.save().then(() => {
          this.hide()
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext('Could not save task'))
          this.hide()
        })
      },
      reject: () => {
        this.hide()
      }
    })
  }

  get users(): User[] {
    return userServiceApi.getUsers( ).data || []
  }

  addAttendee() {
    const parts: string[] = this.newParticipant.split(" <")
    if (parts.length < 1) return
    const email = parts[parts.length - 1].replace('>', '')

    if (email === "") {
      return
    } else if (!!this.attendees.find(a => a.attendee.email === email)) {
      this.newParticipant = ""
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const attendee: Attendee = new Attendee()
      const user: User | undefined = this.users.find(u => u.email === email)
      attendee.email = (user && user.email) ? user.email : email
      attendee.name = (user && user.displayName) ? user.displayName : email
      attendee.participationLevel = 'REQUIRED'
      this.attendees.push({
        attendee: attendee,
        user: user
      })
      this.newParticipant = ""
    } else {
      this.toast.error(this.i18n.$gettext("Member must be an email address"))
    }
  }

  addOrRemoveVideoConference() {
    if (this.conferences && this.conferences.length > 0) {
      this.removeConference(this.conferences[0])
    } else if (this.featureSubset.conferenceUrl) {
      if (!this.conferences) {
        this.conferences = []
      }
      let uri = this.featureSubset.conferenceUrl
      if (!uri.endsWith('/')) {
        uri += '/'
      }
      for (let i = 0; i < 3; i++) {
        uri += Math.random().toString(36).substr(2)
      }
      const conference: Conference = new Conference()
      conference.uri = uri
      this.conferences.push(conference)
      this.editor?.insertContent('<p>Meeting-Link: <a href="' + uri + '"></a>' + uri + '</p>')
    }
  }

  removeConference(conference: any) {
    const i: number = this.conferences.indexOf(conference)
    if (i >= 0) {
      this.conferences.splice(i, 1)
      let html = this.editor?.getHTML() || ''
      html = html.replace(/<p>Meeting-Link:.*<\/p>/, '')
      if (html && html.trim() !== '') {
        this.editor.setContent(html)
      } else if (this.editor) {
        this.editor?.clearContent()
      }
    }
  }

  removeAttendee(email: string) {
    this.attendees = this.attendees.filter(attendee => attendee.attendee.email !== email)
  }

  removeAttachment(attachment: any) {
    if (this.taskAttachments) {
      const index: number = this.taskAttachments.indexOf(attachment)
      if (index >= 0) {
        this.taskAttachments.splice(index, 1)
      }
    }
  }

  @Watch('editor')
  watchEditor(newEditor: TipTapTextArea, oldEditor: TipTapTextArea) {
    if (this.email?.htmlBody && this.email?.htmlBody !== '' && this.editor) {
      this.editor.setContent(this.email.htmlBody)
    } else if (this.email?.textBody && this.email?.textBody !== '' && this.editor) {
      this.editor.setContent(this.email.textBody)
    } else if (this.editor) {
      this.editor.clearContent()
    }
  }

  reset(): void {
    this.start = null
    this.due = null
    this.summary = null
    this.completed = null
    this.percentCompleted = null
    this.location = null
    this.taskBoardId = null
    this.classification = 'PRIVATE'
    if (this.editor) {
      this.editor.clearContent()
    }
    this.priority = 'UNDEFINED'
    this.organizer = null
    this.attachments = []
    this.taskAttachments = []
    this.categories = []
    this.attendees = []
    this.contacts = []
    this.alarms = []
    this.color = null
    this.conferences = []
    this.recurring = false
    this.transparency = 'OPAQUE'
    this.recurrenceRule = Object.assign(new RecurrenceRule(), {
      frequency: 'WEEKLY',
      interval: '1'
    })
  }

  get modalStyle(){
    if(breakpointUtil.isOnMobile()){
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "50%", margin: "1rem", height: "100%" }
    }
  }

  newFromEmail() {
    if (!this.email || !this.email.originalId || !this.email.originalParentId) {
      this.hide()
      return
    }

    this.linkDescription = this.email?.subject
    this.leftBackendId = this.email?.originalId as string
    this.leftParentId = this.email?.originalParentId as string
    //this.rightBackend = 'MAIL'
    this.leftType = 'EMAIL'
    this.summary = this.email?.subject || ''
    this.classification = 'PRIVATE'
    if (this.email?.htmlBody && this.editor) {
      this.editor.setContent(this.email?.htmlBody)
    }
    this.priority = 'UNDEFINED'

    this.showDialogInternal = true
  }

  @Watch("showDialog")
  watchShowDialog(showDialog: boolean, oldValue: boolean) {
    if (showDialog) {
      this.reset()

      /***
       * Add more sources here
       * A source has to set:
       *  - leftBackendId
       *  - leftType
       *
       *  Optionally:
       *  - linkDescription
       *  - leftParentId
       *  - any of the task properties (e.g.: summary)
       *
       *  Finally set showDialogInernal = true to show dialog
       *  of call hide() in case of a problem
       */
      if (this.email) {
        this.newFromEmail()
      } else {
        //No source found
        this.hide()
      }
    } else {
      this.hide()
    }
  }

  openNativeFileChooser(){
    this.attachmentcontrol.openNativeFileChooser()
  }

  mounted() {
    this.watchShowDialog(this.showDialog, false)
  }
}
