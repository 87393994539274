
import {Options, Vue} from 'vue-class-component'
import {mailServiceApi} from "@/api/MailServiceApi"
import SWR from "@/api/SWR"
import EmailListItem from "@/components/email/EmailListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Email from "@/model/entry/Email"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MultiSelect from "primevue/multiselect"
import Dropdown from "primevue/dropdown"
import {mailFolderStore} from "@/store/MailFolderStore"
import FocusListener from "@/util/focusUtil"
import demoService from "@/util/demoService"
import Page from "@/model/Page"
import Query from "@/model/common/Query"
import TaskCreator from "@/components/common/TaskCreator.vue"

@Options({
  components: {
    //@ts-ignore
    InfiniteList, Dropdown, EmailListItem, MenuBar, EmailComposer, MultiSelect, TaskCreator
  },
  //@ts-ignore
  props: {
    folderId: String,
    emailId: String,
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: ['compose:email']
})
export default class EmailList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  folderId!: string
  emailId!: string
  searchQuery!: Query | null
  showTaskCreatorDialog: boolean = false
  emailToCreateTaskFrom!: Email

  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  focusListener: FocusListener = new FocusListener(() => {
    if (this.folderId) {
      mailServiceApi.getEmailPreviews(this.folderId, this.sortBy.value, this.systemFlagsContainsOne, null, 0, 100, 30000)
    }
  })

  pageSize = 50
  sortOptions: { value: string, label: string, icon: string, searchValue: string }[] = [
    { value: 'receivedDate:desc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-down', searchValue: 'ctime:desc' },
    { value: 'receivedDate:asc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-up', searchValue: 'ctime:asc' },
    { value: 'from:asc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-up', searchValue: 'meta:from:asc' },
    { value: 'from:desc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-down', searchValue: 'meta:from:desc' }
  ]
  sortBy: { value: string, label: string, icon: string, searchValue: string } = this.sortOptions[0]

  filterOptions: { value: string, label: string, icon: string }[] = [
    { value: 'flagged', label: this.i18n.$gettext('Flagged'), icon: '' },
    { value: 'unread', label: this.i18n.$gettext('Unread'), icon: '' },
  ]
  filterBy: { value: string, label: string, icon: string }[] = []

  setSortBy(event: any): void {
    if (event?.item?.value) {
      this.sortBy = event.item.value
    }
  }

  get sortLabel(): string {
    const sortBy = this.sortBy
    const option: any = this.sortOptions.find(option => option.value === sortBy.value)
    return option ? option.label : ''
  }

  get total(): number | null {
    return this.folderId ? Math.max(mailServiceApi.state.total || 0, this.allItems.length) : this.allItems.length
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.folderId, sortBy.value)
    let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
    if (this.filterBy.find(f => f.value.includes('flagged'))) {
      emails = SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
    }
    return emails
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    const folderId = this.folderId
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    if (this.searchQuery) {
      const searchQuery = this.searchQuery
      return (pageIndex: number, pageSize: number) => {
        //Short refresh threshold because otherwise changing sort order does not always work => needs investigation!
        return mailServiceApi.queryEmails(searchQuery, pageIndex, pageSize, sortBy.searchValue, 1000)
      }
    } else if (folderId) {
      let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(folderId, sortBy.value, systemFlagsContainsOne, null, pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get systemFlagsContainsOne(): string[] | null {
    const filterBy = this.filterBy
    let systemFlagsContainsOne: string[] | null = null
    if (filterBy.find(f => f.value.includes('unread'))) {
      systemFlagsContainsOne = []
      systemFlagsContainsOne.push('UNSEEN')
    }
    if (filterBy.find(f => f.value.includes('flagged'))) {
      if (!systemFlagsContainsOne) {
        systemFlagsContainsOne = []
      }
      systemFlagsContainsOne.push('FLAGGED')
    }
    return systemFlagsContainsOne
  }

  get isSentFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Sent')
  }

  get isDraftFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Drafts')
  }

  selectEmail(email: Email): void {
    if (email && email.originalId && email.systemFlags && this.isDraftFolder) {
      mailServiceApi.getFullMail(email.originalId).then((fullMail: Email | null) => {
        if (fullMail) this.composer.show(fullMail, email.originalId, this.folderId, null, null)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
      })
    } else if (email.originalId) {
      const originalId: string = email.originalId
      void mailServiceApi._updateFlags(originalId, {SEEN: true}, {}).finally(() => {
        void mailServiceApi.getFullMail(originalId).then((fullMail: Email | null) => {
          if (fullMail && !fullMail.systemFlags) fullMail.systemFlags = [ 'SEEN' ]
          else if (fullMail && !fullMail.systemFlags?.includes('SEEN')) fullMail.systemFlags?.push('SEEN')
        })
      })
      void this.$router.push('/mail/' + this.folderId + '/' + email.originalId)
    }
  }

  flagMessage(event: {id: string, folderId: string, systemFlags: any}) {
    mailServiceApi._updateFlags(event.id, event.systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not update flag"))
    })
  }

  deleteMessage(event: {id: string, folderId: string}) {
    mailServiceApi._deleteMail(event.id).then(() => {
      this.toast.success(this.i18n.$gettext("Email deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete message"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      void this.$router.push('/mail/' + (this.$route.params['folder'] || ''))
    } else {
      return null
    }
  }

  moveToFolder(event: {id: string, sourceFolderId: string, targetFolderId: string}) {
    mailServiceApi._move([ event.id ], event.sourceFolderId, event.targetFolderId, false).then(() => {
      this.toast.success(this.i18n.$gettext("Email moved"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not move email"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      void this.$router.push('/mail/' + (this.$route.params['folder'] || ''))
    } else {
      return null
    }
  }

  showTaskCreator(email: Email) {
    this.emailToCreateTaskFrom = email
    this.showTaskCreatorDialog = true
  }

  replyMessage(event: {id: string, folderId: string, replyAll: boolean}): Promise<void> {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const reply: Email = EmailUtil.createReply(email, event.replyAll)
        this.composer.show(reply, null, event.folderId, event.id, null)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  forwardMessage(event: {id: string, folderId: string, replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const forward: Email = EmailUtil.createForward(email)
        this.composer.show(forward, null, event.folderId, null, event.id)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
