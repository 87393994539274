import GeneratedMailServiceApi from "./generated/GeneratedMailServiceApi"
import {reactive} from "@vue/reactivity"
import {emailStore} from "@/store/EmailStore"
import Email from "@/model/entry/Email"
import useToast from "@/util/toasts"
import i18n from "@/util/i18n"
import {rpcClient} from "@/api/WebsocketClient"
import {getWindowNotificationPermission} from "@/util/DesktopNotificationUtil"
import SWR from "@/api/SWR"
import {isViewingMailFolder} from "@/router"

class MailServiceApi extends GeneratedMailServiceApi {

    state: {total: number | null} = reactive({
        total: null
    })

    lastUnseenEmailTimestamp: Date | null = null

    resetState(): void {
        emailStore.state.emails = new Map<string, Email>()
    }

    getFullMail(originalId: string): Promise<Email | null> {
        const email: Email | null = emailStore.state.emails.get(originalId) || null
        if (email && !email.isPreview) {
            return Promise.resolve(email)
        } else {
            const swr: SWR<Email | null, string> = this.getFullEmail(originalId, true)
            if (swr.call?.promise) {
                //@ts-ignore
                return swr.call.promise.then(id => swr.data || emailStore.state.emails.get(id) || null)
            } else {
                //@ts-ignore
                return Promise.resolve(swr.data)
            }
        }
    }

    initNotificationWatcher(): void {
        rpcClient.registerHandlerMethod("notify_emails", (emails: Email[] | null) => {
            if (emails) {
                this.toastNewMessages(emails)
            }
        })
    }

    toastNewMessages(emails: Email[], title: string = "") {
        const mailsToToast: Email[] = []
        emails.forEach((mail) => {
            if (mail && (!mail.originalParentId || !isViewingMailFolder(mail.originalParentId))) {
                mailsToToast.push(mail)
            }
        })
        if (mailsToToast.length === 0) return
        const toast = useToast()
        const onlyOne: boolean = mailsToToast.length === 1
        if(title === "") {
            if(onlyOne){
                title = i18n.$gettext("New Email message from") + " " + (mailsToToast[0].fromName || []).join(', ')
            } else {
                const translated = i18n.$gettext("You have %{ n } unread emails")
                title = i18n.interpolate(translated, { n: mailsToToast.length })
            }
        }
        const ellipsis = (mailsToToast[0].subject?.length || 0) > 50
        const preview = onlyOne ? mailsToToast[0].subject?.substring(0, 50).replace("\n", " ") + ( ellipsis ? "..." : "") : i18n.$gettext("Click here to read them")
        if (getWindowNotificationPermission() === "granted") {
            this.createNativeNotificationWithTimeout(title, preview, 10000)
        } else {
            toast.info(title)
        }
    }

    createNativeNotificationWithTimeout(header: string, body: string, timeout: number): void {
        const n: Notification = new Notification(header, {body, icon: "/images/elly_logo.png"})
        n.onclick = function(){
            window.focus()
            n.close()
        }
        window.setTimeout(() => {
            n.close()
        }, timeout)
    }

    extractParentIdFromEMailId(originalId: string): string {
        const encoded: string = atob(originalId)
        if (!encoded.includes('::')) return ""
        const parentPart: string = encoded.split('::')[0]
        return this.b64EncodeUnicode(parentPart)
    }

    // https://stackoverflow.com/a/30106551
    b64EncodeUnicode(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }
}

export const mailServiceApi = new MailServiceApi()
