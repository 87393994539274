
import {Options, Vue} from "vue-class-component"
import Task from "../../model/entry/Task"
import AnimatedInput from "../common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Watch} from "vue-property-decorator"
import CalendarPicker from "primevue/calendar"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Attendee from "@/model/common/caldav/Attendee"
import Alarm from "@/model/common/caldav/Alarm"
import Conference from "@/model/common/caldav/Conference"
import {taskServiceApi} from "@/api/TaskServiceApi"
import InputSwitch from "primevue/inputswitch"
import {ref} from "@vue/reactivity"
import InfiniteList from "@/components/common/InfiniteList.vue"
import User from "@/model/User"
import TaskBoard from "@/model/directory/TaskBoard"
import Avatar from "@/components/common/Avatar.vue"
import {userServiceApi} from "@/api/UserServiceApi"
import UserPicker from "@/components/common/UserPicker.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import RecurrencePicker from "@/components/common/RecurrencePicker.vue"
import Organizer from "@/model/common/caldav/Organizer"
import {rpcClient} from "@/api/WebsocketClient"
import RecurrenceRule from "@/model/common/caldav/RecurrenceRule"
import ColorPicker from "@/components/common/ColorPicker.vue"
import OverlayPanel from "primevue/overlaypanel"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import DatePicker from "@/components/common/DatePicker.vue"
import EmailUtil from "@/util/EmailUtil"
import AutoComplete from "@/components/common/AutoComplete.vue"
import Textarea from "primevue/textarea"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import Attachment from "@/model/common/caldav/Attachment"
import Chip from "primevue/chip"
import dayjs from "dayjs"
import Slider from 'primevue/slider'
import breakpointUtil from "@/util/BreakpointUtil"
import TipTapTextArea from "@/components/common/TipTapTextArea.vue"
import Menu from "primevue/menu"
import featureSubset from "@/util/FeatureSubsets"
import Tags from "@/components/common/Tags.vue"
import AttachmentItem from "@/components/common/AttachmentItem.vue"
import {entryLinkServiceApi} from "@/api/EntryLinkServiceApi"
import EntryLink from "@/model/EntryLink"
import {toEmail} from "@/router"

@Options({
  components: {
    //@ts-ignore
    AnimatedInput, LoadingButton, InputText, Dialog, CalendarPicker, InputSwitch, InfiniteList, Avatar,
    UserPicker, AutoComplete, Dropdown, RecurrencePicker, ColorPicker, OverlayPanel, Button, TokenAttachmentList,
    DatePicker, Textarea, Chip, Slider, TipTapTextArea, Menu, Tags, AttachmentItem
  },
  //@ts-ignore
  props: {
    task: [Task, Object],
    boardId: String,
    forbiddenLabels: {
      type: Array,
      default: []
    }
  },
  emits: [
    'hide'
  ]
})
export default class TaskDetails extends Vue {

  featureSubset = featureSubset

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  forbiddenLabels: string[] = []
  loadingEntryLink: boolean = true
  entryLinks: { type: string, parent: string, id: string, description: string }[] = []

  //@ts-ignore
  colorPicker: OverlayPanel = ref<OverlayPanel>(null)
  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  description: HTMLElement = ref<HTMLElement>(null)

  saveLoading = false

  task!: Task | null
  visibleInternal = false
  editMode = false

  showRecurrencePicker = false

  newParticipant = ""
  selectableUsers: string[] = []

  start: Date | null = null
  due: Date | null = null
  summary: string | null = null
  completed: Date | null = null
  percentCompleted: number | null = null
  location: string | null = null
  taskBoardId: string | null = null
  classification = 'PRIVATE'
  priority = 'UNDEFINED'
  organizer: Organizer | null = null
  categories: string[] = []
  list: string | null = null
  attendees: { attendee: Attendee, user: User | undefined }[] = []
  contacts: string[] = []
  alarms: Alarm[] = []
  color: string | null = null
  conferences: Conference[] = []
  recurring = false
  transparency = 'OPAQUE'
  recurrenceRule: RecurrenceRule = Object.assign(new RecurrenceRule(), {
    frequency: 'WEEKLY',
    interval: '1'
  })

  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  //@ts-ignore
  attachMenu: Menu = ref(null)
  attachments: {name: string, size: string, handle: string, loading: boolean, progress: number}[] = []

  attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      const query: string = event.query.toLowerCase()
      return user.userName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.displayName?.toLowerCase()?.indexOf(query) !== -1 ||
        user.email?.toLowerCase()?.indexOf(query) !== -1 ||
        user.uid?.toLowerCase()?.indexOf(query) !== -1
    }).map((user: User) => {
      let userTag: string = ""
      if (user.email) userTag = user.email
      if (user.displayName) userTag = user.displayName + " <" + userTag + ">"
      return userTag
    }).filter((str: string) => {return str !== "" })
  }

  saveTaskListItemChanges() {
    if (this.task?.originalId && !this.saveLoading && this.canEdit && this.editor?.hasChanges()) {
      this.task.description = this.editor.getMarkdown()
      this.saveLoading = true
      return taskServiceApi._updateTask(this.task).then((id: string) => {
        return id
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Updating task failed"))
      }).finally(() => {
        this.saveLoading = false
      })
    }
  }

  organizerImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  organizerName(email: string, name: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return name ? name : email
    }
  }

  save(): Promise<string | void> {
    if (this.validate && this.taskBoardId) {
      let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
      if (hasIncompleteUploads){
        this.toast.error(this.i18n.$gettext("You cannot send the message while attachments are still uploading"))
        return Promise.reject()
      }

      if (this.start && this.due && this.due.getTime() < this.start.getTime()){
        this.toast.error(this.i18n.$gettext("Due date must be after start date"))
        return Promise.reject()
      }

      const task: Task = this.task ? Object.assign(new Task(), this.task) : new Task()

      if (this.percentCompleted === 100 && task.percentCompleted !== 100) {
        this.completed = new Date()
      }

      task.start = this.start?.toISOString() || null
      task.due = this.due?.toISOString() || null
      task.completed = this.completed?.toISOString() || null
      task.percentCompleted = this.percentCompleted
      task.summary = this.summary
      task.location = this.location
      task.originalParentId = this.taskBoardId
      task.classification = this.classification
      task.description = this.editor?.getMarkdown()
      task.priority = this.priority
      task.organizer = this.organizer ? this.organizer : (rpcClient.session.user ? Object.assign(new Organizer(), {
        email: rpcClient.session.user.email,
        name: rpcClient.session.user.userName, //TODO
      }) : null)
      task.categories = [...this.categories]
      if (this.list && task.categories.indexOf(this.list) < 0) {
        task.categories.push(this.list)
      }
      task.attendees = (this.attendees || []).map(a => a.attendee)
      for (let attendee of task.attendees) {
        attendee.rsvp = true //TODO
      }
      task.contacts = this.contacts
      task.alarms = this.alarms
      task.color = this.color
      task.conferences = this.conferences
      task.recurrenceRule = this.recurring ? this.recurrenceRule : null
      task.fileTokens = this.attachmentcontrol.getFileTokens()

      this.saveLoading = true

      if (this.task?.originalId) {
        return taskServiceApi._updateTask(task).then((id: string) => {
          this.editMode = false
          this.attachments = []
          this.saveLoading = false
          return id
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Updating task failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      } else {
        return taskServiceApi._addTask(task).then((id: string) => {
          this.editMode = false
          this.attachments = []
          const created: Task | undefined = taskServiceApi.getTask(id)
          if (created && this.task) {
            Object.assign(this.task, created)
          } else if (created) {
            this.watchEvent(task, null)
          }
          return id
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Creating task failed"))
        }).finally(() => {
          this.saveLoading = false
        })
      }
    } else {
      return Promise.reject()
    }
  }

  get validate(): boolean {
    return Boolean(this.summary)
  }

  classificationOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('Public'), value: 'PUBLIC'},
    {label: this.i18n.$gettext('Private'), value: 'PRIVATE'},
    {label: this.i18n.$gettext('Confidential'), value: 'CONFIDENTIAL'}
  ]

  priorityOptions: {label: string, value: string}[] = [
    {label: this.i18n.$gettext('High'), value: 'HIGH'},
    {label: this.i18n.$gettext('Medium'), value: 'MEDIUM'},
    {label: this.i18n.$gettext('Low'), value: 'LOW'},
    {label: this.i18n.$gettext('Not set'), value: 'UNDEFINED'}
  ]

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Member')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Assignee')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Stakeholder')
    }
  ]

  filterCategoriesOptions(currentInput: string): string[] {
    const options: string[] = taskServiceApi.getCategoriesOptions(this.taskBoardId || "")
    if (currentInput) {
      const lowerQuery = currentInput.toLowerCase()
      return options.filter(option => option.toLowerCase().includes(lowerQuery)).sort((o1: string, o2: string) => {
        let index1: number = o1.toLowerCase().indexOf(lowerQuery)
        let index2: number = o2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
    } else {
      return options
    }
  }

  hide() {
    this.attachments = []
    this.$emit('hide')
  }

  confirmClose() {
    if (this.editMode && this.hasChanges) {
      this.confirm.require({
        message: this.i18n.$gettext('Do you want to save the task?'),
        header: this.i18n.$gettext('Save & Close'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save().then(() => {
            this.hide()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext('Could not save task'))
            this.hide()
          })
        },
        reject: () => {
          this.hide()
        }
      })
    } else {
      this.hide()
    }
  }

  get taskBoards(): TaskBoard[] | null {
    return taskBoardServiceApi.getTaskBoards().data
  }

  get canEdit(): boolean {
    const canWrite: string[] = ['WRITE', 'OWNER']
    const board: TaskBoard | undefined = this.taskBoards?.find(b => b.originalId === this.task?.originalParentId)
    return Boolean( board && canWrite.includes(board.shareAccess || ''))
  }

  get users(): User[] {
    return userServiceApi.getUsers( ).data || []
  }

  get taskBoardName(): string {
    if (this.task?.originalParentId) {
      return this.taskBoards?.find(b => b.originalId === this.task?.originalParentId)?.name || ''
    } else {
      return ''
    }
  }

  get attachmentsWithFileName(): Attachment[] {
    return this.task?.attachments?.filter(a => a.file && a.file.fileName) || []
  }

  get startDate(): string {
    return this.task?.start ? dayjs(this.task.start).format('dddd, LLL') : ''
  }

  get dueDate(): string {
    return this.task?.due ? dayjs(this.task.due).format('dddd, LLL') : ''
  }

  get completedDate(): string {
    return this.task?.completed ? dayjs(this.task.completed).format('dddd, LLL') : ''
  }

  get members(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'OPTIONAL') || []
  }

  get assignees(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'REQUIRED') || []
  }

  get stakeholders(): Attendee[] {
    return this.task?.attendees?.filter(a => a.participationLevel === 'FYI') || []
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  attendeeName(email: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return email
    }
  }

  addAttendee() {
    const parts: string[] = this.newParticipant.split(" <")
    if (parts.length < 1) return
    const email = parts[parts.length - 1].replace('>', '')

    if (email === "") {
      return
    } else if (!!this.attendees.find(a => a.attendee.email === email)) {
      this.newParticipant = ""
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const attendee: Attendee = new Attendee()
      const user: User | undefined = this.users.find(u => u.email === email)
      attendee.email = (user && user.email) ? user.email : email
      attendee.name = (user && user.displayName) ? user.displayName : email
      attendee.participationLevel = 'REQUIRED'
      this.attendees.push({
        attendee: attendee,
        user: user
      })
      this.newParticipant = ""
    } else {
      this.toast.error(this.i18n.$gettext("Member must be an email address"))
    }
  }

  addOrRemoveVideoConference() {
    if (this.conferences && this.conferences.length > 0) {
      this.removeConference(this.conferences[0])
    } else if (this.featureSubset.conferenceUrl) {
      if (!this.conferences) {
        this.conferences = []
      }
      let uri = this.featureSubset.conferenceUrl
      if (!uri.endsWith('/')) {
        uri += '/'
      }
      for (let i = 0; i < 3; i++) {
        uri += Math.random().toString(36).substr(2)
      }
      const conference: Conference = new Conference()
      conference.uri = uri
      this.conferences.push(conference)
      this.editor?.insertContent('<p>Meeting-Link: <a href="' + uri + '"></a>' + uri + '</p>')
    }
  }

  removeConference(conference: any) {
    const i: number = this.conferences.indexOf(conference)
    if (i >= 0) {
      this.conferences.splice(i, 1)
      let html = this.editor?.getHTML() || ''
      html = html.replace(/<p>Meeting-Link:.*<\/p>/, '')
      if (html && html.trim() !== '') {
        this.editor.setContent(html)
      } else if (this.editor) {
        this.editor?.clearContent()
      }
    }
  }

  removeAttendee(email: string) {
    this.attendees = this.attendees.filter(attendee => attendee.attendee.email !== email)
  }

  removeAttachment(attachment: any) {
    if (this.task?.attachments) {
      const index: number = this.task.attachments.indexOf(attachment)
      if (index >= 0) {
        this.task.attachments.splice(index, 1)
      }
    }
  }

  get hasChanges(): boolean {
    if (this.task?.originalId) {
      let taskBoard: TaskBoard | undefined =  this.taskBoards?.find(board => board.originalId === this.taskBoardId)
      return Boolean(((!this.task?.start && this.start) || (this.start && this.start.getTime() !== new Date(this.task?.start || 0).getTime())) ||
        ((!this.task?.due && this.due) || (this.due && this.due.getTime() !== new Date(this.task?.due || 0).getTime())) ||
        (this.taskBoardId !== this.task?.originalParentId) ||
        (this.percentCompleted !== this.task?.percentCompleted) ||
        (this.completed !== this.task?.completed) ||
        (this.summary !== this.task?.summary) ||
        (this.location !== this.task?.location) ||
        (this.classification !== (this.task?.classification || 'PRIVATE')) ||
        //TODO (this.description !== this.task?.description) ||
        (this.priority !== (this.task?.priority || 'UNDEFINED')) ||
        (this.color !== this.task?.color) ||
        //(this.recurrenceRule !== this.task?.recurrenceRule) ||
        (this.organizer !== (this.task?.organizer || null)) ||
        //(this.transparency !== (this.task?.transparency || 'OPAQUE')) ||
        !SortAndFilterUtil.arrayEquals(this.categories, (this.task?.categories || []).filter((category: string) => {
          return !taskBoard || !taskBoard.meta?.taskLists?.map(list => list.name).includes(category)
        })) ||
        !SortAndFilterUtil.arrayEquals((this.attendees || []).map(a => a.attendee), this.task?.attendees || []) ||
        !SortAndFilterUtil.arrayEquals(this.contacts, this.task?.contacts || []) ||
        !SortAndFilterUtil.arrayEquals(this.alarms, this.task?.alarms || []) ||
        !SortAndFilterUtil.arrayEquals(this.conferences, this.task?.conferences || []))
    } else {
      return Boolean(this.task && this.validate)
    }
  }

  @Watch('editor')
  watchEditor(newEditor: TipTapTextArea, oldEditor: TipTapTextArea) {
    if (this.task?.description && this.task.description !== '' && this.editor) {
      this.editor.setContent(this.task.description)
    } else if (this.editor) {
      this.editor.clearContent()
    }
  }

  @Watch('task')
  watchEvent(task: Task | null, oldValue: Task | null) {
    if (!task) {
      this.visibleInternal = false
      this.editMode = false
      this.start = null
      this.due = null
      this.summary = null
      this.completed = null
      this.percentCompleted = null
      this.location = null
      this.taskBoardId = null
      this.classification = 'PRIVATE'
      if (this.editor) {
        this.editor.clearContent()
      }
      this.priority = 'UNDEFINED'
      this.organizer = null
      this.categories = []
      this.attendees = []
      this.contacts = []
      this.alarms = []
      this.color = null
      this.conferences = []
      this.recurring = false
      this.transparency = 'OPAQUE'
      this.recurrenceRule = Object.assign(new RecurrenceRule(), {
        frequency: 'WEEKLY',
        interval: '1'
      })
    } else if (!this.editMode) {
      this.editMode = !task.originalId
      this.visibleInternal = true
      let taskBoard: TaskBoard | undefined = undefined
      this.start = task.start ? new Date(task.start) : null
      this.due = task.due ? new Date(task.due) : null
      this.completed = task.completed ? new Date(task.completed) : null
      this.percentCompleted = task.percentCompleted
      this.summary = task.summary
      this.location = task.location
      if (task.originalParentId) {
        this.taskBoardId = task.originalParentId
        if (this.taskBoards) taskBoard = this.taskBoards.find(board => board.originalId === this.taskBoardId)
      } else {
        this.taskBoardId = this.taskBoards ? (this.taskBoards[0]?.originalId || null) : null
      }
      this.classification = task.classification || 'PRIVATE'
      if (task.description && this.editor) {
        this.editor.setContent(task.description)
      }
      this.priority = task.priority || 'UNDEFINED'
      if (task.organizer) {
        this.organizer = task.organizer
      } else {
        this.organizer = null
      }
      if (task.categories) {
        this.categories = task.categories.filter((category: string) => {
          return !taskBoard || !taskBoard.meta?.taskLists?.map(list => list.name?.toLowerCase()).includes(category.toLowerCase())
        })
        this.list = task.categories.find((category: string) => {
          return taskBoard && taskBoard.meta?.taskLists?.map(list => list.name?.toLowerCase()).includes(category.toLowerCase())
        }) || null
      } else {
        this.categories = []
      }
      if (task.attendees) {
        this.attendees = task.attendees.map(a => {
          return {
            attendee: a,
            user: this.users.find(u => u.email === a.email)
          }
        })
      } else {
        this.attendees = []
      }
      if (task.contacts) {
        this.contacts = task.contacts
      } else {
        this.contacts = []
      }
      if (task.alarms) {
        this.alarms = task.alarms
      } else {
        this.alarms = []
      }
      this.color = task.color
      if (task.conferences) {
        this.conferences = task.conferences
      } else {
        this.conferences = []
      }
      if (task.recurrenceRule) {
        this.recurring = true
        this.recurrenceRule = task.recurrenceRule
      } else {
        this.recurring = false
      }
    }
    if (task) {
      this.checkForEntryLinks()
    }
  }
  
  checkForEntryLinks() {
    this.entryLinks = []
    if (this.task && this.task.originalId) {
      entryLinkServiceApi._getLinksByTypeAndBackendId(
        "TASK", this.task.originalId
      ).then((entryLinks: EntryLink[]) => {
        if (entryLinks && entryLinks.length > 0) {
          let linkedId: string | null
          let linkedParentId: string | null
          let linkedType: string | null
          let linkedDescription: string | null
          entryLinks.forEach((entry: EntryLink) => {
            if (this.task && entry.meta) {
              linkedDescription = entry.meta.description
              if (entry.leftBackendId == this.task.originalId) {
                linkedId = entry.rightBackendId
                linkedParentId = entry.meta.rightOriginalParentId
                linkedType = entry.rightType
              } else {
                linkedId = entry.leftBackendId
                linkedParentId = entry.meta.leftOriginalParentId
                linkedType = entry.leftType
              }
              if (linkedId && linkedType == 'EMAIL' && linkedParentId && linkedDescription) {
                this.entryLinks.push({
                  type: linkedType,
                  parent: linkedParentId,
                  id: linkedId,
                  description: linkedDescription
                })
              }
            }
          })
        }
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get linked entries"))
      }).finally(() => {
        this.loadingEntryLink = false
      })
    }
  }

  get modalStyle(){
    if(breakpointUtil.isOnMobile()){
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "50%", margin: "1rem", height: "100%" }
    }
  }

  get hasLink(): boolean {
    if (this.loadingEntryLink) return false
    return this.entryLinks.length > 0
  }

  goToEmail(entry: {type: string, parent: string, id: string}): void {
    toEmail(entry.parent, entry.id)
  }

  mounted() {
    this.watchEvent(this.task, null)
  }

  openNativeFileChooser(){
    this.attachmentcontrol.openNativeFileChooser()
  }
}
